/* ##
 * # 
 * # Add here your custom script(s)
 * # 
 * # -- Last Script added : dd / mm / yyyy
 * ## ===================================== ## */


jQuery(document).ready(function () {

    /** Force Get a Quote Form Phone to pop the Number Pad on mobile devices */
    if (jQuery('.ginput_container_phone input').length > 0) {
        jQuery('.ginput_container_phone input').attr('type', 'tel');
    }

    if (jQuery('.ginput_container_address.has_zip .ginput_address_zip input').length > 0) {
        jQuery('.ginput_container_address.has_zip .ginput_address_zip input').attr('type', 'tel');
    }


    /** Load Process My Quote Redirection */
    processMyQuoteRedirect();
})





/**
 * Process My Quote - Page Redirection
 * ---
 */
function processMyQuoteRedirect() {
    console.log('Binding Gravity Forms...');
    jQuery(document).bind("gform_confirmation_loaded", function (event, formId) {
        console.log('Form ID: ', formId);
        console.log('URL: ', sessionStorage.getItem('redirect_url'));

        if (4 == formId || 6 == formId) {
            window.location.href = sessionStorage.getItem('redirect_url');
        }
    });
}